<template>
  <div class="px-4 py-2 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="row cab-listado mb-3">
              <div class="col-2">
                <h5 class="mb-0">Usuarios</h5>
              </div>
              <div class="col-8">
                <div id="buscador">
                  <input type="text" v-model="textoBuscador" placeholder="Búsqueda rápida"/>
                  <i class="fas fa-times borrarBuscador" @click="() => { paginaActual=1;textoBuscador='';obtenerListadoUsuarios();}"></i>
                  <a title="Buscar" class="btn-verde" style="" @click="() => { paginaActual=1;obtenerListadoUsuarios();}"><i class="fas fa-search"></i></a>
                </div>
              </div>
              <div class="col-1 borrados">
                <soft-switch id="borrados-switch" name="borrados" @change="() => {paginaActual=1;textoBuscador='';mostrarBorrados=!mostrarBorrados;obtenerListadoUsuarios();}" :checked="mostrarBorrados">Elim.</soft-switch>
              </div>
              <div class="col-1">
                <a title="Alta de Usuario" class="btn-verde page-link" style="float:right;" @click="() => { altaUsuarioModal(); tipoModal='A'; }">+</a>
              </div>
            </div>
          </div>
          <div class="px-4 pb-0 card-body">
            <div class="table-responsive">
              <table id="usuarios-list" ref="usuariosList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th>Usuario</th>
                    <th>Nombre</th>
                    <th>Email</th>
                    <th>Rol</th>
                    <th>Tipster</th>
                    <th>Creado</th>
                    <th v-if="mostrarBorrados">Eliminado</th>
                    <th>Acciones</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <tr v-for="usuario in usuarios.rows" :key="usuario.id">
                    <td>{{ usuario.username }}</td>
                    <td>{{ usuario.nombre }}</td>
                    <td>{{ usuario.email }}</td>
                    <td>{{ usuario.rol_name }}</td>
                    <td>{{ usuario.tipster_name }}</td>
                    <td>{{ formatDate(usuario.created_at,'DD/MM/YYYY') }}</td>
                    <td v-if="mostrarBorrados">{{ usuario.deleted_at ? formatDate(usuario.deleted_at,'DD/MM/YYYY') : '' }}</td>
                    <td>
                      <a v-if="!usuario.deleted_at"
                        @click="() => { editaUsuarioModal(); tipoModal='E'; usuarioEditId=usuario.id; usuarioEditName=usuario.nombre; usuarioEditusername=usuario.username; usuarioEditemail=usuario.email; usuarioEditrol=usuario.rol_id.toString(); usuarioEditTipster=usuario.tipster_id.toString(); }"
                        :id="'edit-' + usuario.id"
                        class="actionButton cursor-pointer me-3"
                        data-bs-toggle="tooltip"
                        title="Editar Usuario"
                      >
                        <i class="fas fa-user-edit text-secondary"></i> </a
                      >
                      <a v-if="!usuario.deleted_at"
                        @click="() => { cambioClaveUsuarioModal(); tipoModal='C'; usuarioEditId=usuario.id; usuarioEditusername=usuario.username; usuarioEditemail=usuario.email; }"
                        :id="'clave-' + usuario.id"
                        class="actionButton cursor-pointer me-3"
                        data-bs-toggle="tooltip"
                        title="Generar clave aleatoria"
                      >
                        <i class="fas fa-key text-secondary"></i> </a
                      ><a v-if="!usuario.deleted_at"
                        @click="mostrarConfirmacionBorrar(usuario.id)"
                        :id="'borrar-' + usuario.id"
                        class="actionButton deleteButton cursor-pointer"
                        data-bs-toggle="tooltip"
                        title="Eliminar Usuario"
                      >
                        <i class="fas fa-trash text-secondary"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="d-flex justify-content-center justify-content-sm-between flex-wrap"
            style="padding: .75rem .75rem 0px"
          >
            <div>
              <p class="contador-listado">Mostrando {{ usuarios.mostradosIni }} a {{ usuarios.mostradosFin }} de {{ usuarios.totales }} registros</p>
            </div>
            <ul class="pagination pagination-success pagination-md">
              <li class="page-item prev-page" v-if="usuarios.actual>1">
                <a class="page-link" aria-label="Previous" @click="paginaActual=paginaActual-1;obtenerListadoUsuarios();">
                  <span aria-hidden="true">
                    <i class="fa fa-angle-left" aria-hidden="true"></i>
                  </span>
                </a>
              </li>
              <li v-for="index in usuarios.paginas" :key="index" class="page-item" :class="index==usuarios.actual ? 'active disabled' : ''">
                <a class="page-link" @click="paginaActual=index;obtenerListadoUsuarios();">{{index}}</a>
              </li>
              <li class="page-item next-page" v-if="usuarios.actual<usuarios.paginas">
                <a class="page-link" aria-label="Next" @click="paginaActual=paginaActual+1;obtenerListadoUsuarios();">
                  <span aria-hidden="true"
                    ><i class="fa fa-angle-right" aria-hidden="true"></i
                  ></span>
                </a>
              </li>
              <li class="" style="width:36px;" v-if="usuarios.actual==usuarios.paginas">
                <!-- FILLER -->
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Modal @close="altaUsuarioModal" @cerrar-modal="cerrarModal" :modalActive="modalActive">
    <div class="modal-content">
      <alta-usuario-form v-if="tipoModal=='A'" @cerrar-modal="() => { cerrarModal();obtenerListadoUsuarios();}" :key="0"></alta-usuario-form>
      <edita-usuario-form v-if="tipoModal=='E'" @cerrar-modal="() => { cerrarModal();obtenerListadoUsuarios();}" :key="usuarioEditId" :usuarioId="usuarioEditId" :usuarioName="usuarioEditName" :usuarioUsername="usuarioEditusername" :usuarioEmail="usuarioEditemail" :usuarioRolId="usuarioEditrol" :usuarioTipsterId="usuarioEditTipster"></edita-usuario-form>
      <cambio-clave-usuario-form v-if="tipoModal=='C'" @cerrar-modal="() => { cerrarModal();}" :key="usuarioEditId" :usuarioId="usuarioEditId" :usuarioUsername="usuarioEditusername" :usuarioEmail="usuarioEditemail"></cambio-clave-usuario-form>
    </div>
  </Modal>

  <div v-if="confirmacionVisible" class="overlay">
    <div class="confirm-box">
      <p class="mt-4 mb-4">{{ mensajeConfirmacion }}</p>
      <table class="mt-4 mb-4"><tr>
        <td width="50%"><button class="btn-cancela" @click="cancelacion">Cancelar</button></td>
        <td width="50%"><button class="btn-confirma" @click="confirmacion">Confirmar</button></td>
      </tr></table>
    </div>
  </div>
</template>

<script>
import showSwal from "@/mixins/showSwal.js";
import SoftSwitch from "@/components/SoftSwitch.vue";
import Modal from './components/Modal.vue';
import AltaUsuarioForm from './components/AltaUsuarioForm.vue';
import EditaUsuarioForm from './components/EditaUsuarioForm.vue';
import CambioClaveUsuarioForm from './components/CambioClaveUsuarioForm.vue';
import {ref} from 'vue';
import {globalFunctionsMixin} from '@/mixins/globalFunctions.js';

export default {
  name: "Usuarios",
  components: {
    Modal,
    AltaUsuarioForm,
    EditaUsuarioForm,
    CambioClaveUsuarioForm,
    SoftSwitch
  },
  mixins: [globalFunctionsMixin],
  data() {
    return {
      usuarios: [],
      confirmacionVisible: false,
      usuarioId: 0,
      mensajeConfirmacion: '',
      tipoModal: '',
      usuarioEditId: 0,
      usuarioEditName: '',
      usuarioEditusername: '',
      textoBuscador: '',
      paginaActual: 0,
      paginaTotal: 0,
      mostrarBorrados: false
    };
  },

  methods: {
    alert() {
      showSwal.methods.showSwal({
        type: "error",
        message: "This is a PRO feature.",
        width: 400,
      });
    },
    mostrarConfirmacionBorrar(id) { 
      this.confirmacionVisible = true;
      this.usuarioId = id;
      this.mensajeConfirmacion = '¿Confirma que desea eliminar este usuario?';
    },
    confirmacion() {
      this.confirmacionVisible = false;
      this.borrarUsuario(this.usuarioId);
    },
    cancelacion() {
      this.confirmacionVisible = false;
    },
    async obtenerListadoUsuarios() {
      try {
        await this.$store.dispatch("auth/usuarios",{textoBuscador: this.textoBuscador, paginaActual: this.paginaActual, mostrarBorrados: this.mostrarBorrados});
        this.usuarios = JSON.parse(localStorage.getItem("usuarios"));
      } 
      catch (error) {
        showSwal.methods.showSwal({
            type: "error",
            message: "Se ha producido un error",
            width: 500,
        });
      }
    },
    async borrarUsuario(usuarioId) {
      try {
        await this.$store.dispatch("auth/borraUsuario",{id: usuarioId});
        const resp = JSON.parse(localStorage.getItem("respBorraUsuario"));
        if (resp.res=='OK') {
            showSwal.methods.showSwal({
                type: "success",
                message: resp.mensaje,
                width: 600,
            });
            this.obtenerListadoUsuarios();
            this.$emit('cerrar-modal');
        }
        else {
            showSwal.methods.showSwal({
                type: "error",
                message: resp.mensaje,
                width: 500
            });               
        }
      } 
      catch (error) {
        showSwal.methods.showSwal({
            type: "error",
            message: "Se ha producido un error",
            width: 500,
        });
      }
    },
  },

  setup() { 
    const modalActive = ref(false);

    const altaUsuarioModal = () => {
      modalActive.value = !modalActive.value;
    };

    const editaUsuarioModal = () => {
      modalActive.value = !modalActive.value;
    };

    const cambioClaveUsuarioModal = () => {
      modalActive.value = !modalActive.value;
    };

    const cerrarModal = () => { 
      modalActive.value = false;
    };

    return { modalActive, altaUsuarioModal, editaUsuarioModal, cambioClaveUsuarioModal, cerrarModal};
  },

  async created() { 
    this.obtenerListadoUsuarios();
  }
};
</script>



<style lang="scss" scoped>
.usuarios {
  background-color: rgba(0, 176, 234, 0.5);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    display: flex;
    flex-direction: column;

    h1,
    p {
      margin-bottom: 16px;
    }

    h1 {
      font-size: 32px;
    }

    p {
      font-size: 18px;
    }
  }
}
</style>
