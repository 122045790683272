<template>
    <h5>Alta de Usuario</h5>
    <Form role="form"
          class="text-start"
          :validation-schema="schema"
          @submit="handleAltaUsuario"
          >
        <!--<label for="nombre">Usuario</label>-->
        <soft-field
            id="username"
            v-model="user.username"
            type="text"
            placeholder="Usuario"
            name="username"
            class="mb-2"
            largo="30"
        />

        <!--<label for="nombre">Nombre</label>-->
        <soft-field
            id="nombre"
            v-model="user.name"
            type="text"
            placeholder="Nombre"
            name="nombre"
            class="mb-2"
            largo="80"
        />

        <!--<label for="email">Email</label>-->
        <soft-field
            id="email"
            v-model="user.email"
            type="email"
            placeholder="Email"
            name="email"
            class="mb-2"
        />

        <!--<label for="roles">Rol</label>-->
        <soft-select
            id="rolid"
            v-model="user.rolid"
            name="rolid"
            :valores="roles"
            placeholder="Seleccione rol"
            class="mb-2"
        />

        <!--<label for="tipsters">Tipster</label>-->
        <soft-select
            id="tipsterid"
            v-model="user.tipsterid"
            name="tipsterid"
            :valores="tipsters"
            placeholder="Seleccione Tipster"
            class="mb-2"
        />
  
        <div class="text-center mt-5">
            <soft-button
                class="my-2 mb-2 text-sm"
                color="cardama"
                full-width
                :is-disabled="loading ? true : false"
                >
                <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                ></span>
                <span v-else>Confirmar</span>
            </soft-button>
        </div>
    </Form>
  </template>
  
  <script>
  import SoftField from "@/components/SoftField.vue";
  import SoftSelect from "@/components/SoftSelect.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import showSwal from "/src/mixins/showSwal.js";
  import { Form } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "AltaUsuarioForm",
    components: {
        SoftField,
        SoftSelect,
        SoftButton,
        Form,
    },
    data() {
        const schema = yup.object().shape({
            email: yup.string().required("El email es necesario!").email("Debe indicar un email válido"),
            username: yup.string().required("El usuario es necesario!").max(30,'El tamaño máximo es de 30 caracteres').matches(/^[a-zA-Z0-9.-]+$/, 'El usuario contiene caracteres no permitidos'),
            nombre: yup.string().required("El nombre es necesario!").max(80,'El tamaño máximo es de 80 caracteres'),
            rolid: yup.number().positive('Debe indicar un rol'),
        /*    tipsterid: yup.number().when(['rolid', 'tipsterid'], {
                is: (rolId, tipsterId) => rolId > 1 && tipsterId !== undefined, // Aplicar la validación solo si rol_id > 1 y tipster_id está definido
                then: yup.number().positive('Debe ser un número positivo').required('El tipster es necesario').min(1, 'Debe ser mayor que cero'), // Validación si rol_id > 1
                otherwise: yup.number().test('is-zero', 'Rol administrador no admite Tipster', value => value === 0) // Validación si rol_id es 1
            })*/
        });
        const roles = [{id:1,nombre:'Administrador'},{id:2,nombre:'Admin.Tipster'},{id:3,nombre:'Cliente'},]; 
        this.$store.dispatch("auth/listaTipsters");
        const tipsters = JSON.parse(localStorage.getItem("listaTipsters")); console.log(tipsters);
        return {
            loading: false,
            user:{},
            schema,
            roles: roles,
            tipsters: tipsters
        };
    },
    emits: ['cerrar-modal'],
    methods: {
      async handleAltaUsuario() {
        // Aquí puedes realizar acciones cuando se envía el formulario
        console.log('Formulario enviado:', this.user);
        this.loading = true;
        try {
            await this.$store.dispatch("auth/guardaUsuario", this.user);
            this.loading = false;
            const resp = JSON.parse(localStorage.getItem("respGuardaUsuario"));
            if (resp.res=='OK') {
                showSwal.methods.showSwal({
                    type: "success",
                    message: "Se ha registrado el nuevo usuario",
                    width: 600,
                });
                this.$emit('cerrar-modal');
            }
            else {
                showSwal.methods.showSwal({
                    type: "error",
                    message: resp.mensaje,
                    width: 500
                });               
            }
        } catch (error) {
            this.loading = false;
            showSwal.methods.showSwal({
                type: "error",
                message: "Se ha producido un error",
                width: 500,
            });
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente MyForm */
  </style>