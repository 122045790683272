<template>
  <main class="mt-0 main-content main-content-bg"
    :style="{
      backgroundImage: 'url(' + require('@/assets/img/carrera-caballos.jpg') + ')',
      backgroundSize: 'cover',
    }">
    <section style="background-color: rgba(0, 0, 0, 0.5);">
      <div class="page-header min-vh-75">
        <div class="container">
          <div class="row vh-100 hstack">
            <div class="col-md-3">
            </div>
            <div class="mx-auto col-xl-4 col-lg-5 col-md-6">
              <div class="card card-plain" style="padding:1rem;box-shadow:0 0 10px rgba(0,0,0,0.7);">
                <div class="pt-0 pb-0 card-header text-left">
                  <p class="text-cardama mb-0" style="font-weight: 800;font-size: 1.2rem;">
                    Accede a tu cuenta:
                  </p>
                <!--<p class="mb-0">Indica tus credenciales de acceso:</p>-->
                </div>
                <div class="card-body pb-3">
                  <Form
                    role="form"
                    class="text-start"
                    :validation-schema="schema"
                    @submit="handleLogin"
                  >
                    <!--<label for="usuario">Usuario / Email</label>-->
                    <soft-field
                      id="usuario"
                      v-model="user.usuario"
                      type="text"
                      placeholder="Usuario o email"
                      name="usuario"
                      class="mb-4"
                    />

                    <!--<label>Clave</label>-->
                    <soft-field
                      id="password"
                      v-model="user.password"
                      type="password"
                      placeholder="Clave"
                      name="password"
                      class="mb-4"
                    />

                    <div class="text-center">
                      <soft-button
                        class="my-2 mb-2 text-sm"
                        color="cardama"
                        full-width
                        :is-disabled="loading ? true : false"
                      >
                        <span
                          v-if="loading"
                          class="spinner-border spinner-border-sm"
                        ></span>
                        <span v-else>Acceder</span>
                      </soft-button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div class="col-md-3">
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
<!--<app-footer />-->
</template>

<script>
/*import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";*/
import SoftField from "@/components/SoftField.vue";
import SoftButton from "@/components/SoftButton.vue";
import showSwal from "/src/mixins/showSwal.js";
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";
import { Form } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
/*    Navbar,
    AppFooter,*/
    SoftField,
    SoftButton,
    Form,
  },

  data() {
    const schema = yup.object().shape({
      usuario: yup.string().required("Debe indicar su usuario!"),
      password: yup.string().required("Debe indicar su clave!"),
    });
    const user = {
      usuario: "",
      password: "",
    };
    return {
      loading: false,
      user,
      schema,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-100");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    async handleLogin() {
      this.loading = true;
      try {
        await this.$store.dispatch("auth/login", this.user);
        this.$router.push("/dashboard");
      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Datos de acceso erróneos",
        });
        this.loading = false;
      }
    },
  },
};
</script>
