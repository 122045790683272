<template>
    <h5>Modificar Usuario</h5>
    <Form role="form"
          class="text-start"
          :validation-schema="schema"
          @submit="handleEditaUsuario"
          >
        <!--<label for="nombre">Usuario</label>-->
        <soft-field
            id="username"
            v-model="usuarioEditusername"
            type="text"
            placeholder="Usuario"
            name="username"
            class="mb-2"
            largo="30"
        />

        <!--<label for="nombre">Nombre</label>-->
        <soft-field
            id="nombre"
            v-model="usuarioEditName"
            type="text"
            placeholder="Nombre"
            name="nombre"
            class="mb-2"
            largo="80"
        />

        <!--<label for="email">Email</label>-->
        <soft-field
            id="email"
            v-model="usuarioEditemail"
            type="email"
            placeholder="Email"
            name="email"
            class="mb-2"
        />

        <!--<label for="roles">Rol</label>-->
        <soft-select
            id="rolid"
            v-model="usuarioEditrol"
            name="rolid"
            :valores="roles"
            placeholder="Seleccione rol"
            class="mb-2"
        />

        <!--<label for="tipsters">Tipster</label>-->
        <soft-select
            id="tipsterid"
            v-model="usuarioEditTipster"
            name="tipsterid"
            :valores="tipsters"
            placeholder="Seleccione Tipster"
            class="mb-2"
        />

        <div class="text-center mt-5">
            <soft-button
                class="my-2 mb-2 text-sm"
                color="cardama"
                full-width
                :is-disabled="loading ? true : false"
                >
                <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                ></span>
                <span v-else>Confirmar</span>
            </soft-button>
        </div>
    </Form>
  </template>
  
  <script>
  import SoftField from "@/components/SoftField.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import SoftSelect from "@/components/SoftSelect.vue";
  import showSwal from "/src/mixins/showSwal.js";
  import { Form } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "EditaUsuarioForm",
    components: {
        SoftField,
        SoftButton,
        SoftSelect,
        Form,
    },
    props: {
        usuarioId: Number,
        usuarioName: String,
        usuarioUsername: String,
        usuarioEmail: String,
        usuarioRolId: String,
        usuarioTipsterId: String
    },
    data() {
        const schema = yup.object().shape({
            email: yup.string().required("El email es necesario!").email("Debe indicar un email válido"),
            username: yup.string().required("El usuario es necesario!").max(30,'El tamaño máximo es de 30 caracteres').matches(/^[a-zA-Z0-9.-]+$/, 'El usuario contiene caracteres no permitidos'),
            nombre: yup.string().required("El nombre es necesario!").max(80,'El tamaño máximo es de 80 caracteres'),
            rol_id: yup.number().positive('Debe indicar un rol')
        });
        const roles = [{id:1,nombre:'Administrador'},{id:2,nombre:'Admin.Tipster'},{id:3,nombre:'Cliente'},]; 
        this.$store.dispatch("auth/listaTipsters");
        const tipsters = JSON.parse(localStorage.getItem("listaTipsters")); 
        return {
            loading: false,
            schema,
            roles: roles,
            tipsters: tipsters,
            user:  {},
            usuarioEditId: this.usuarioId,
            usuarioEditName: this.usuarioName,
            usuarioEditusername: this.usuarioUsername,
            usuarioEditemail: this.usuarioEmail,
            usuarioEditrol: this.usuarioRolId,
            usuarioEditTipster: this.usuarioTipsterId,
        };
    },
    emits: ['cerrar-modal'],
    methods: {
        async handleEditaUsuario() {
            // Aquí puedes realizar acciones cuando se envía el formulario
            this.loading = true;
            try {             
                this.user['id'] = this.usuarioId; 
                this.user['name'] = this.usuarioEditName; 
                this.user['username'] = this.usuarioEditusername; 
                this.user['email'] = this.usuarioEditemail; 
                this.user['rolid'] = this.usuarioEditrol; 
                this.user['tipsterid'] = this.usuarioEditTipster; 
                await this.$store.dispatch("auth/guardaUsuario", this.user);
                this.loading = false;
                const resp = JSON.parse(localStorage.getItem("respGuardaUsuario"));
                if (resp.res=='OK') {
                    showSwal.methods.showSwal({
                        type: "success",
                        message: "Se ha actualizado el usuario",
                        width: 600,
                    });
                    this.$emit('cerrar-modal');
                }
                else {
                    showSwal.methods.showSwal({
                        type: "error",
                        message: resp.mensaje,
                        width: 500
                    });               
                }
            } catch (error) {
                this.loading = false;
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Se ha producido un error",
                    width: 500,
                });
            }
        },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente MyForm */
  </style>