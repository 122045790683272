import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/api/v2/';

export default {
  async login(user) {
    const response = await axios.post(API_URL + "login", user);
    if (response.data.access_token) {
      localStorage.setItem("userF", JSON.stringify(response.data.access_token));
    }
  },

  async logout() {
    try {
      await axios.post(API_URL + "logout", {}, { headers: authHeader() });
    } finally {
      localStorage.removeItem("userF");
    }
  },

  async register(user) {
    const response = await axios.post(API_URL + "register", user);
    if (response.data.access_token) {
      localStorage.setItem("userF", JSON.stringify(response.data.access_token));
    }
  },


  // TIPSTERS
  async tipsters(tipster) {
    const response = await axios.post(API_URL + "tipsters", tipster, { headers: authHeader() });
    if (response.data) {
      localStorage.setItem("tipsters", JSON.stringify(response.data));
    }
  },

  async listaTipsters() {
    const response = await axios.post(API_URL + "listaTipsters", null, { headers: authHeader() });
    if (response.data) {
      localStorage.setItem("listaTipsters", JSON.stringify(response.data));
    }
  },

  async guardaTipster(tipster) { 
    const response = await axios.post(API_URL + "guardaTipster", tipster, { headers: authHeader() });
    if (response.data) {
      localStorage.setItem("respGuardaTipster", JSON.stringify(response.data));
    }
  },

  async borraTipster(userId) { 
    const response = await axios.post(API_URL + "borraTipster", userId, { headers: authHeader() });
    if (response.data) {
      localStorage.setItem("respBorraTipster", JSON.stringify(response.data));
    }
  },


  // USUARIOS
  async usuarios(usuario) {
    const response = await axios.post(API_URL + "usuarios", usuario, { headers: authHeader() });
    if (response.data) {
      localStorage.setItem("usuarios", JSON.stringify(response.data));
    }
  },

  async guardaUsuario(usuario) { 
    const response = await axios.post(API_URL + "guardaUsuario", usuario, { headers: authHeader() });
    if (response.data) {
      localStorage.setItem("respGuardaUsuario", JSON.stringify(response.data));
    }
  },

  async borraUsuario(userId) { 
    const response = await axios.post(API_URL + "borraUsuario", userId, { headers: authHeader() });
    if (response.data) {
      localStorage.setItem("respBorraUsuario", JSON.stringify(response.data));
    }
  },

  async generarClaveUsuario(usuario) { 
    const response = await axios.post(API_URL + "generarClaveUsuario", usuario, { headers: authHeader() });
    if (response.data) {
      localStorage.setItem("respgenerarClaveUsuario", JSON.stringify(response.data));
    }
  },
};
