<template>
  <div class="form-group">
    <select
      :id="id"
      class="form-control"
      :placeholder="placeholder"
      :value="modelValue"
      @change="$emit('update:modelValue', $event.target.value)"
    >
      <option v-for="valor in valores" :key="valor.id" :value="valor.id">{{ valor.nombre }}</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "SoftSelect",
  props: {
    id: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "Seleccione...",
    },
    name: {
      type: String,
      default: "",
    },
    valores: {
      type: Array,
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
};
</script>
