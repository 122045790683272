import authService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("userF"));
const initialState = user ? { loggedIn: true } : { loggedIn: false };

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    async login({ commit }, userData) {
      await authService.login(userData);
      commit("logMeIn");
    },

    async logout({ commit }) {
      try {
        await authService.logout();
      } finally {
        commit("logMeOut");
      }
    },

    async register({ commit }, userData) {
      await authService.register(userData);
      commit("logMeIn");
    },


    // TIPSTERS
    async tipsters(context,tipster) {
      await authService.tipsters(tipster);
    },

    async listaTipsters() {
      await authService.listaTipsters();
    },

    async guardaTipster(context, tipster) { 
      await authService.guardaTipster(tipster);
    },

    async borraTipster(context, tipster) { 
      await authService.borraTipster(tipster);
    },


    // USUARIOS
    async usuarios(context,usuario) {
      await authService.usuarios(usuario);
    },

    async guardaUsuario(context, usuario) { 
      await authService.guardaUsuario(usuario);
    },

    async borraUsuario(context, usuario) { 
      await authService.borraUsuario(usuario);
    },

    async generarClaveUsuario(context, usuario) { 
      await authService.generarClaveUsuario(usuario);
    },
  },
  mutations: {
    logMeIn(state) {
      state.loggedIn = true;
    },
    logMeOut(state) {
      state.loggedIn = false;
    },
  },

  getters: {
    loggedIn(state) {
      return state.loggedIn;
    },
  },
};
