<template>
  <nav
    class="shadow-none navbar navbar-main navbar-expand-lg border-radius-xl"
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-0 py-1 container-fluid">
      <h4>{{ currentRouteName }}</h4>
      <div id="navbar"
        class="mt-2 mt-sm-0 my-auto ms-auto"
        :class="this.$store.state.isRTL ? 'px-0' : ''"
      >
        <ul class="navbar-nav justify-content-end">
          <li class="nav-item d-flex align-items-center px-3">
            <router-link
              v-if="!loggedIn"
              :to="{ name: 'Login' }"
              class="px-0 nav-link font-weight-bold"
              :class="textWhite ? textWhite : 'text-body'"
            >
              <i
                class="fa fa-user"
                :class="this.$store.state.isRTL ? 'ms-sm-2' : 'me-sm-1'"
              ></i>
              <span v-if="this.$store.state.isRTL" class="d-sm-inline d-none"
                >يسجل دخول</span
              >
              <span v-else class="d-sm-inline d-none">Sign In </span>
            </router-link>

            <a
              v-else
              class="px-0 nav-link font-weight-bold"
              :class="textWhite ? textWhite : 'text-body'"
              style="cursor: pointer"
              @click="logoutUser"
            >
              <i
                class="fa fa-power-off"
                :class="this.$store.state.isRTL ? 'ms-sm-2' : 'me-sm-1'"
              ></i>
              <span v-if="this.$store.state.isRTL" class="d-sm-inline d-none"
                >تسجيل الخروج</span
              >
              <span v-else class="d-sm-inline d-none">&nbsp;&nbsp;Desconectar </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
/*import Breadcrumbs from "../Breadcrumbs.vue";*/
import { mapMutations, mapActions } from "vuex";

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false,
    };
  },
  props: ["minNav", "textWhite"],
  created() {
    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },

    async logoutUser() {
      try {
        await this.$store.dispatch("auth/logout");
      } finally {
        this.$router.push("/login");
      }
    },
  },
  components: {
  /*  Breadcrumbs,*/
  },
  computed: {
    currentRouteName() {
      if (this.$route.name=='Dashboard') {
        return 'Panel';
      }
      else if (this.$route.name=='Profile') {
        return 'Mi Usuario';
      }
      return this.$route.name;
    },
    loggedIn() {
      return this.$store.getters["auth/loggedIn"];
    },
  },
  updated() {
    const navbar = document.getElementById("navbarBlur");
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10 && this.$store.state.isNavFixed) {
        navbar.classList.add("blur");
        navbar.classList.add("position-sticky");
        navbar.classList.add("shadow-blur");
      } else {
        navbar.classList.remove("blur");
        navbar.classList.remove("position-sticky");
        navbar.classList.remove("shadow-blur");
      }
    });
  },
};
</script>
<style>
a:hover {
  cursor: pointer;
}
</style>