<template>
    <h5 class="mb-4">Modificar Tipster</h5>
    <Form role="form"
          class="text-start"
          :validation-schema="schema"
          @submit="handleEditaTipster"
          >

        <!--<label for="nombre">Nombre</label>-->
        <soft-field
            id="nombre"
            v-model="tipsterEditName"
            type="text"
            placeholder="Nombre"
            name="nombre"
            class="mb-2"
            largo="80"
        />

        <div class="text-center mt-5">
            <soft-button
                class="my-2 mb-2 text-sm"
                color="cardama"
                full-width
                :is-disabled="loading ? true : false"
                >
                <span
                    v-if="loading"
                    class="spinner-border spinner-border-sm"
                ></span>
                <span v-else>Confirmar</span>
            </soft-button>
        </div>
    </Form>
  </template>
  
  <script>
  import SoftField from "@/components/SoftField.vue";
  import SoftButton from "@/components/SoftButton.vue";
  import showSwal from "/src/mixins/showSwal.js";
  import { Form } from "vee-validate";
  import * as yup from "yup";

  export default {
    name: "EditaTipsterForm",
    components: {
        SoftField,
        SoftButton,
        Form,
    },
    props: {
        tipsterId: Number,
        tipsterName: String,
    },
    data() {
        const schema = yup.object().shape({
            nombre: yup.string().required("El nombre es necesario!").max(80,'El tamaño máximo es de 80 caracteres'),
        });
        //console.log('Tengo ' + this.tipsterId + ' ' + this.tipsterRolId);
        return {
            loading: false,
            schema,
            user:  {},
            tipsterEditId: this.tipsterId,
            tipsterEditName: this.tipsterName,
        };
    },
    emits: ['cerrar-modal'],
    methods: {
        async handleEditaTipster() {
            // Aquí puedes realizar acciones cuando se envía el formulario
            this.loading = true;
            try {             
                this.user['id'] = this.tipsterEditId; 
                this.user['nombre'] = this.tipsterEditName; 
                await this.$store.dispatch("auth/guardaTipster", this.user);
                this.loading = false;
                const resp = JSON.parse(localStorage.getItem("respGuardaTipster"));
                if (resp.res=='OK') {
                    showSwal.methods.showSwal({
                        type: "success",
                        message: "Se ha actualizado el tipster",
                        width: 600,
                    });
                    this.$emit('cerrar-modal');
                }
                else {
                    showSwal.methods.showSwal({
                        type: "error",
                        message: resp.mensaje,
                        width: 500
                    });               
                }
            } catch (error) {
                this.loading = false;
                showSwal.methods.showSwal({
                    type: "error",
                    message: "Se ha producido un error",
                    width: 500,
                });
            }
        },
    },
  };
  </script>
  
  <style scoped>
  /* Estilos específicos del componente MyForm */
  </style>